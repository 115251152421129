import React from 'react';


const Nav = ({onClickNavItem}) => {
    return (
       <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon mdi mdi-floor-plan"></i>
                  <span className="menu-title">Sample JSONs</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic" >
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"><span className="nav-link hover-cursor" onClick={()=>onClickNavItem('simple')}>Simple</span></li>
                    <li className="nav-item"><span className="nav-link hover-cursor" onClick={()=>onClickNavItem('array')}>Array</span></li>
                    <li className="nav-item"><span className="nav-link hover-cursor" onClick={()=>onClickNavItem('complex')}>Complex</span></li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
    )
}


export default Nav;