import React, {Fragment, useState,useEffect} from 'react';
import Val from "./ValueObj";
import Key from "./KeyObj";

const KeyValPair = ({keyVal, value, collapseAll}) => {
    const [keyClicked, setKeyClicked] = useState(false)

    useEffect(()=>{
        setKeyClicked(collapseAll)
    }, [collapseAll])

    let valType = typeof(value)
    let arraySize = null
    if (Array.isArray(value)){
        valType = 'Array'
        arraySize = value.length
    }else if (value===null){
        valType = 'Null'
    }else if (value===undefined){
        valType = 'Undefined'
    }else if (typeof(value)==='object'){
        valType = 'Object'
    }

    return (
        <Fragment>
            <Key keyVal={keyVal}
                 valType={valType}
                 keyClicked={keyClicked}
                 setKeyClicked={setKeyClicked}
                 arraySize={arraySize}
            />
            <td><Val value={value} keyClicked={keyClicked} collapseAll={collapseAll}/></td>
        </Fragment>
    )
}


export default KeyValPair;