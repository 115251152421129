import React, {Fragment, useEffect, useState} from 'react';
import Val from "./ValueObj";

const IndexRow = ({keys, index, item, topKeyClicks, collapseAll}) => {
    const [keyClicked, setKeyClicked] = useState(false)

    useEffect(()=>{
        setKeyClicked(collapseAll)
    }, [collapseAll])

    let render = "";
    if (keys!==undefined){
        render = <Fragment>
            <td key={String(index)+'index'} className={'text-muted'}>
                <i
                  className={keyClicked?'mdi mdi-arrow-down-drop-circle-outline':"mdi mdi-arrow-up-drop-circle-outline"}
                  onClick={()=>setKeyClicked(!keyClicked)}> </i>
                {" "+ index+" "}
            </td>
            {keys.map(keyVal=><td key={keyVal+String(index)}>
                <Val keyClicked={keyClicked || topKeyClicks[keyVal]} value={item[keyVal]} collapseAll={collapseAll}/>
            </td>)}
        </Fragment>
    }else{
        render = <Fragment>
            <td className={'text-muted'}>
                <i
                  className={keyClicked?'mdi mdi-arrow-down-drop-circle-outline':"mdi mdi-arrow-up-drop-circle-outline"}
                  onClick={()=>setKeyClicked(!keyClicked)}> </i>
                {" "+ index+" "}
            </td>
            <td>
                <Val keyClicked={keyClicked} value={item} collapseAll={collapseAll}/>
            </td>
        </Fragment>
    }

    return (
        render
    )
}


export default IndexRow;