import React, {Fragment} from 'react';
import KeyValPair from "./KeyValPair";

const Dict = ({obj, collapseAll}) => {
    const isEmpty = Object.keys(obj).length === 0;
    const render = isEmpty? <Fragment>{"{}"}</Fragment>:
           <table>
              <tbody>
                {Object.entries(obj).map(
                    ([keyVal, value]) =>
                        <tr key={keyVal}>
                            <KeyValPair keyVal={keyVal} value={value} collapseAll={collapseAll}/>
                        </tr>
                )}
              </tbody>
            </table>

    return (
        render
    )
}


export default Dict;