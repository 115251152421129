import React, {Fragment, useState, useEffect} from 'react';
import IndexRow from "./IndexRow";

const compareKeys = (a, b) => {
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}


const List = ({value, collapseAll}) => {
    const [topKeyClicks, setTopKeyClicks] = useState({})
    const [keys, setKeys] = useState([])
    const [isSame, setIsSame] = useState(true)

    const onClickTopKey = (keyVal) =>{
        let temp = Object.create(topKeyClicks)
        temp[keyVal] = !temp[keyVal]
        setTopKeyClicks(temp)
    }

    useEffect(()=>{
        let is_same = true
        let first;
        value.map(item => {
            if (item === null) {
                is_same = false;
            }else if (typeof(item)!=='object') {
                is_same = false;
            }else if(first!==undefined) {
                if (!compareKeys(first, item)){
                    is_same = false;
                }
            }else {
                first = item
            }
            return null
        })

        setIsSame(is_same)
        if (is_same){
            if (first!==undefined) {
                let tempKeys = Object.keys(first)
                setKeys(tempKeys)

                let topKeys = Object.fromEntries(tempKeys.map(k => [k, false]))
                setTopKeyClicks(topKeys)
            }
        }

    }, [value, collapseAll])


    return (
        <Fragment>
        {!value.length?'[]':
            isSame?<table>
                <tbody>
                <tr>
                    <th key='index' className={'text-muted text-small'}> Index</th>
                    {keys.map(keyVal => <th key={keyVal}>
                        <i className={topKeyClicks[keyVal]?'mdi mdi-arrow-down-drop-circle-outline':"mdi mdi-arrow-up-drop-circle-outline"}
                           onClick={() => onClickTopKey(keyVal)}> </i>
                        {keyVal}
                    </th>)}
                </tr>
                {value.map((item, index)=><tr key={index}><IndexRow keys={keys}
                                                                    item={item}
                                                                    index={index}
                                                                    topKeyClicks={topKeyClicks}
                                                                    collapseAll={collapseAll}
                /></tr>)}
                </tbody>
        </table>:<table className={'ListTable'}>
            <tbody>
            <tr>
                <th className={'text-muted text-small'} style={{padding:'5px'}}> Index</th>
                <th> </th>
            </tr>
                {value.map((item, index)=><tr key={index}>
                    <IndexRow item={item} index={index} collapseAll={collapseAll}/>
                </tr>)}
            </tbody>
        </table>
        }
        </Fragment>
    )
}

export default List;