import React from 'react';

const Key = ({keyVal, valType, keyClicked, setKeyClicked, arraySize}) => {
    return (
       <th className={"keyword"}>
           <div>
               <i
                  className={keyClicked?'mdi mdi-arrow-down-drop-circle-outline':"mdi mdi-arrow-up-drop-circle-outline"}
                  onClick={()=>setKeyClicked(!keyClicked)}></i>
               {" "+ keyVal+" "}
               <p className={'text-small text-muted'}>{valType}{arraySize!==null?" - ("+arraySize+")":""}</p>
           </div>
       </th>
    )
}

export default Key;