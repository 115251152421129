import React, {Fragment} from 'react';
import Dict from "./DictObj";
import List from "./ListObj";

const Val = ({keyClicked, value, collapseAll}) => {
    let render_result;

    if (Array.isArray(value)){
        render_result = keyClicked?<Fragment>{"[...]"}</Fragment>:<List value={value}/>
    }else if (value===null){
        render_result = <Fragment>{String(value)}</Fragment>
    }else if (value===undefined){
        render_result = <Fragment>{value}</Fragment>
    }else if (typeof(value)==='object'){
        render_result = keyClicked?<Fragment>{"{...}"}</Fragment>:<Dict obj={value} collapseAll={collapseAll}/>
    }else{
        render_result = keyClicked?value?.length >= 6?<Fragment>...</Fragment>:<Fragment>{String(value)}</Fragment>:<Fragment>{String(value)}</Fragment>
    }

    return (
        render_result
    )

}

export default Val;