import React, {useState} from 'react';
import InputComponent from "./InputComponent";
import MainTable from "./MainTable";
import Nav from "./Nav";
import ScrollToTop from "react-scroll-to-top";

const sampleJson = {
  simple: {
  "data": "Click Here",
  "size": 36,
  "style": "bold",
  "name": "text1",
  "hOffset": 250,
  "vOffset": 100,
  "alignment": "center",
  "onMouseUp": "sun1.opacity = (sun1.opacity / 100) * 90;"
},
  array: {
    "same_Key_Objects_Array":[{"id":"CopySVG","label":"Copy SVG","type":"SVG"},{"id":"ViewSVG","label":"View SVG","type":"SVG"},{"id":"ViewSource","label":"View Source","type":"Source"},{"id":"SaveAs","label":"Save As","type":"Button"},{"id":"OpenNew","label":"Open New","type":"Button"},{"id":"ZoomIn","label":"Zoom In","type":"Action"},{"id":"ZoomOut","label":"Zoom Out","type":"Action"}],"different_Items_Array":[{"id":"Open"},null,"test string",{"id":"Copy"},{"id":"CopyAgain","label":"Copy Again"},1233],"Array_of_Arrays":[["a","v","c","d"],["q","w","e","r"],["z","x","c","v"],["y","u","i","i"]]},
  complex: {
  "items": {
    "item": {
      "id": "0001",
      "type": "donut",
      "name": "Cake",
      "ppu": 0.55,
      "batters": {
        "batter": [
          {
            "id": "1001",
            "type": "Regular"
          },
          {
            "id": "1002",
            "type": "Chocolate"
          },
          {
            "id": "1003",
            "type": "Blueberry"
          },
          {
            "id": "1004",
            "type": "Devil's Food"
          }
        ]
      },
      "topping": [
        {
          "id": "5001",
          "type": "None"
        },
        {
          "id": "5002",
          "type": "Glazed"
        },
        {
          "id": "5005",
          "type": "Sugar"
        },
        {
          "id": "5007",
          "type": "Powdered Sugar"
        },
        {
          "id": "5006",
          "type": "Chocolate with Sprinkles"
        },
        {
          "id": "5003",
          "type": "Chocolate"
        },
        {
          "id": "5004",
          "type": "Maple"
        }
      ]
    }
  }
},
}


const Main = () => {
  const [jsonData, setJsonData] = useState()

  const onClickNavItem = (type) => {
      setJsonData(sampleJson[type])
  }

  const onClickSocial = (link) => {
    window.open(link);
  }

  return (
        <div className="container-scroller">
        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
            <div className="me-3">
              <button className="navbar-toggler navbar-toggler align-self-center" type="button"
                      data-bs-toggle="minimize"><span className="icon-menu"></span></button>
            </div>

          </div>
          <div className="navbar-menu-wrapper d-flex align-items-top">
            <ul className="navbar-nav">
              <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                <h1 className="welcome-text text-black fw-bold">JSON Visualizer</h1>
                <h3 className="welcome-sub-text">A simple tool to visualize JSON in tabular form.</h3>
              </li>
            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    data-bs-toggle="offcanvas"><span className="mdi mdi-menu"></span></button>
          </div>
        </nav>

        <div className="container-fluid page-body-wrapper">
          <Nav onClickNavItem={onClickNavItem}/>
          <div className="main-panel">
            <ScrollToTop smooth style={{backgroundColor: "silver"}}/>
            <div className="content-wrapper">
              <div className="row">
                <InputComponent sampleJson={jsonData} setJsonState={setJsonData}/>
                <MainTable jsonObj={jsonData}/>
              </div>
            </div>

            <footer className="footer">
              <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Developed by <span className={'text-black fw-bold'}>Muhammad Tauseeq</span>
                </span>

                <div className={'btn-group small'}>
                  <button className={'btn btn-social-icon btn-linkedin btn-rounded'} onClick={()=>onClickSocial("https://www.linkedin.com/in/muhammad-tauseeq-06ba21157/")}>
                    <i className={'mdi mdi-linkedin'}> </i>
                  </button>
                  <button className={'btn btn-social-icon btn-youtube btn-rounded'} onClick={() => window.location = 'mailto:mtuseeq.531@gmail.com'}>
                    <i className={'mdi mdi-gmail'}> </i>
                  </button>
                  <button className={'btn btn-social-icon btn-facebook btn-rounded'} onClick={()=>onClickSocial("https://mtuseeq.medium.com/")}>
                    <i className={'mdi mdi-medium'}> </i>
                  </button>
                </div>

                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Copyright © 2021. All rights reserved.</span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    )
}


export default Main;