import React, {useState, useRef, useEffect} from 'react';
import {EditorState, basicSetup} from "@codemirror/basic-setup"
import {EditorView, placeholder} from "@codemirror/view";
import {Compartment} from "@codemirror/state";
import {json, jsonLanguage, jsonParseLinter} from "@codemirror/lang-json";
import {bracketMatching} from "@codemirror/matchbrackets";
import {linter, lintGutter} from "@codemirror/lint";
import ReactTooltip from "react-tooltip";


const myTheme = EditorView.theme({
  "&": {
      textAlign: "left",
      height: "350px",
      padding: 0,
      borderStyle: "groove"
  },
})

const linterConf = new Compartment

const dummyLinter = () => (view) => {
    return [];
};

const autolinterConf = EditorState.transactionExtender.of(tr => {
    if (!tr.docChanged) return null
    let docIsHTML = tr.newDoc.toString()
    return {
        effects: linterConf.reconfigure(docIsHTML === ""? linter(dummyLinter()): linter(jsonParseLinter()))
    }
})


const InputComponent = ({sampleJson, setJsonState}) => {
    const [jsonText, setJsonText] = useState('')
    const [topMsg, setTopMsg] = useState('')
    const [msgType, setMsgType] = useState(0)
    const [view, setView] = useState(null)
    const editor = useRef();

    useEffect(()=>{
        let view = new EditorView({
          state: EditorState.create({
              doc:jsonText,
              extensions: [
                  basicSetup,
                  myTheme,
                  json(),
                  bracketMatching(),
                  placeholder('Start typing here or paste JSON here'),
                  jsonLanguage,
                  lintGutter(),
                  linterConf.of(linter(dummyLinter())),
                  autolinterConf,
                  EditorView.updateListener.of((v)=>{
                    if (v.docChanged) {
                        onChangeTextArea(v)
                    }
                  })
              ]
          }),
          parent: editor.current
        })
        setView(view)
        return () => {
              view.destroy();
            };
    }, [])

    useEffect(()=>{
        if (sampleJson!==undefined) {
            setJsonText(JSON.stringify(sampleJson, null, 2))
            const currentValue = view.state.doc.toString();
            const endPosition = currentValue.length;

            view.dispatch({
              changes: {
                from: 0,
                to: endPosition,
                insert: JSON.stringify(sampleJson, null, 2)}
            })
        }
    }, [sampleJson])

    const onClickSubmit = (e) => {
        // Stops page from reloading
        e.preventDefault();
        // Try converting into JSON
        try {
            let parsedJson = JSON.parse(jsonText);
            setJsonState(parsedJson)
        }
        catch(err) {
            setMsgType(2)
            setTopMsg(err.message)
            setJsonState()
        }
    }

    const onChangeTextArea = (v) => {
        try {
            let value = v.state.doc.toString()
            setJsonText(value)
            if (value!==''){
                JSON.parse(value);
                setMsgType(1)
                setTopMsg('Valid Json')
            }else{
                setMsgType(0)
                setTopMsg('')
            }
        }
        catch(err) {
            setMsgType(2)
            setTopMsg(err.message)
        }
    }

    const onClickClear = (e) => {
        // Stops page from reloading
        e.preventDefault();

        const currentValue = view.state.doc.toString();
        const endPosition = currentValue.length;

        view.dispatch({
          changes: {
            from: 0,
            to: endPosition,
            insert: ''}
        })

        setMsgType(0)
        setTopMsg('')

        setJsonState()
        setJsonText()
    }

    const onClickReformat = (e) => {
        // Stops page from reloading
        e.preventDefault();
        try {
            const currentValue = view.state.doc.toString();
            const endPosition = currentValue.length;

            view.dispatch({
                changes: {
                    from: 0,
                    to: endPosition,
                    insert: JSON.stringify(JSON.parse(currentValue), null, 2)
                }
            })
        }catch (e) {
        }
    }

    const onClickMinify = (e) => {
        // Stops page from reloading
        e.preventDefault();
        try {
            const currentValue = view.state.doc.toString();
            const endPosition = currentValue.length;

            view.dispatch({
                changes: {
                    from: 0,
                    to: endPosition,
                    insert: JSON.stringify(JSON.parse(currentValue))
                }
            })
        }catch (e) {
        }
    }

    const onClickCopy = (e) => {
        e.preventDefault()
        if (jsonText!==undefined){
        navigator.clipboard.writeText(jsonText)}
    }

    return (
        <div className="col-lg-8 ml-auto mr-auto grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample">
                  <div className="form-group">
                      <div ref={editor} > </div>
                      {topMsg!==''?<label htmlFor="jsonTextArea"
                             className={msgType===0?"":msgType===1?"text-success":"text-danger"}>
                          {topMsg}
                      </label>:''}
                  </div>
                  <div className={'btn-wrapper'}>
                  <button type="submit"
                          className={msgType===2?"btn btn-rounded btn-secondary me-2":"btn-warning btn btn-rounded me-2"}
                          disabled={msgType===2}
                          onClick={onClickReformat}>Reformat</button>
                  <button type="submit"
                          className={msgType===2?"btn btn-rounded btn-secondary me-2":"btn-warning btn btn-rounded me-2"}
                          disabled={msgType===2}
                          onClick={onClickMinify}>Minify</button>
                  <button className="btn btn-outline-secondary btn-rounded btn-icon" data-tip data-for="copyToClipboard"
                          onClick={onClickCopy}><i className={'mdi mdi-content-copy text-dark'}></i></button>

                  <ReactTooltip id="copyToClipboard" effect="solid">
                    Copy to Clipboard
                  </ReactTooltip>

                  <button type="submit"
                        className="btn btn-primary me-2"
                        onClick={event => onClickSubmit(event)}
                        style={{float: 'right'}}>Generate</button>
                  <button type="submit"
                          className="btn btn-inverse-danger me-2"
                          onClick={e=>onClickClear(e)}
                          style={{float: 'right'}}>Clear</button>
                  </div>
              </form>
            </div>
          </div>
        </div>
    )
}


export default InputComponent;