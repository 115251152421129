import React , {useState} from 'react';
import Val from "./ValueObj";

const MainTable = ({jsonObj}) => {
    const [collapseAll, setCollapseAll] =  useState(false)

    return (
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Json Tabular Form</h3>
                <div className={"card-subtitle"}>
                   <i
                      className={collapseAll?'mdi mdi-arrow-down-drop-circle-outline hover-cursor':"mdi mdi-arrow-up-drop-circle-outline hover-cursor"}
                      onClick={()=>setCollapseAll(!collapseAll)}> {collapseAll?"Expand All":"Collapse All"} </i>
               </div>

              <div className="table-responsive">
                <table className="outPut-table-main">
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <Val collapseAll={collapseAll} value={jsonObj}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    )
}


export default MainTable;